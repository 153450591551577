import React, { useEffect } from "react";
import { Box, Switch, Flex, Button, Text} from "@chakra-ui/react";
import { Header } from "./components/widget/Header";
import { Steps } from "./components/widget/Steps";
import { ShoppingCartProvider } from "./providers/ShoppingCartProvider";
import { ProductProvider } from "./providers/ProductProvider";
import { ModalAddress2 } from "./components/modal/ModalAddress2";
import { HowToWork2 } from "./components/modal/HowToWork2";
import { useWidget } from "./providers/WidgetProvider";
import { AddressProvider } from "./providers/AddressProvider";
import { AuthService } from "./services/AuthService";
import { AskForAddress2 } from "./components/modal/AskForAddress2";
import SimilarOptions from "./components/product/suggestionProduct/SimilarOptions";
import { GlobalPixel } from "./helper/pixel/GlobalPixel";
import { Data } from "./helper/Data";
import { getInstance } from "./helper/BrandFactory";
import ServiceUnavailable from "./components/modal/ServiceUnavailable";


function Widget(): React.JSX.Element {
  const widget = useWidget()
  const styleButton = getInstance().customStyle();
  const hostId = Data.getHostId();
  const ALLOWED_HOST_IDS = ["279038", "4555531", "781264", "218350", "393901", "956487"];
  const isAllowedHost = ALLOWED_HOST_IDS.includes(hostId);
  const isWidgetActive = widget?.isOn;
  const isLowerCase = ['279038'].includes(hostId);


  useEffect(() => {
    localStorage.setItem("trycloud-widget", "true");//Indica cuanddo el componente ya existe en el DOM
    localStorage.setItem("trycloud-rendering", "false");//Indica que el componente ya dejo de renderizarse
    return () => {
      //console.log("Trycloud: widget se ha desmontado");
      localStorage.setItem("trycloud-widget", "false");//Indica si el componente ya no existe en el DOM
      localStorage.setItem("trycloud-rendering", "false");//Indica que el componente ya dejo de renderizarse
    }
  }, []);

  if (widget == null) return(<></>);

  /** Handler the widget, change the state of the widget. */
  const handlerWidget = async (): Promise<void> => {
      if (widget.isOn) {
          widget.closeWidget();
          return;
      }
      if (!AuthService.validateSession()) {
          widget.openCloseModalAskForAddress(true);
      } else {
        widget.openWidget();
      }
      GlobalPixel.activate();
  }

  const PruebaBoton: React.FC<{ isOn: boolean | undefined; handlerWidget: () => void; styleButton: React.CSSProperties }> = ({ isOn, handlerWidget, styleButton }) => (
    <Button onClick={handlerWidget} style={styleButton}>
      {
      isLowerCase ? 
      isOn ? "Cerrar el Probador" : "Pruébatelo antes de comprar" :
      isOn ? "CERRAR EL PROBADOR" : "PRUÉBATELO ANTES DE COMPRAR"
      }
    </Button>
  );
  
  const PruebaSwitch: React.FC<{ isOn: boolean; handlerWidget: () => void }> = ({ isOn, handlerWidget }) => (
    <Flex>
      <Switch
        size="lg"
        pt={0}
        pr={2}
        colorScheme="purple"
        isChecked={isOn}
        onChange={handlerWidget}
      />
      <Text ml={2} fontSize="25px" fontWeight="bold">
        Pruébatelo
      </Text>
    </Flex>
  );

  return (
    <>
    <Box
      maxW={["393901", "956487"].includes(hostId) ? "100%" : "500px"}
      minW="250px"
      minH="75px"
      w="100%"
      mt="10px"
      rounded="10px"
      pb="10px"
    >
      <>
      <Box>
        {isAllowedHost ? (
          <>
            {!isWidgetActive ? (
              <PruebaBoton
                isOn={isWidgetActive}
                handlerWidget={handlerWidget}
                styleButton={styleButton}
              />
            ) : (
              <PruebaSwitch
                isOn={isWidgetActive}
                handlerWidget={handlerWidget}
              />
            )}
          </>
        ) : (
          <Flex>
            <Switch
              size="lg"
              pt={5}
              px={2}
              colorScheme="purple"
              onChange={handlerWidget}
              isChecked={isWidgetActive ?? false}
            />
            <Header />
          </Flex>
        )}
      </Box>
        <ShoppingCartProvider>
          <ProductProvider>
            <AddressProvider>
              <>
                <Steps />
                {
                  widget.isOpenModalAskForAddress &&
                  <AskForAddress2 /* comission={widget?.configData?.commission.toLocaleString('es-co') }*//>
                }
                {
                  widget.isOpenModalAdress && 
                  <ModalAddress2  />
                }
                {
                  widget.isOpenModalServiceUnavailable &&
                  <ServiceUnavailable />
                }
                <SimilarOptions />
              </>
            </AddressProvider>
          </ProductProvider>
        </ShoppingCartProvider>
      </>
     
      {widget.isOpenModalHowTowork && 
        <HowToWork2 />
      }
    </Box>
    </>
  );
}

export default Widget;
